<template>
  <div>
    <div v-if="this.$route.name === 'AccountingCardReport'">
      <AccountingCardReport />
    </div>
    <router-view />
  </div>
</template>
<script>
import AccountingCardReport from "@/views/companyApp/accountingCard/AccountingCardReport.vue";

export default {
  name: 'AccountingCardReportParent',
  components: {
    AccountingCardReport,
  }
}
</script>