<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="$emit('update:dialog', false);"
      max-width="70%"
    >
      <AccountingCardNumberListTable :listSkeleton.sync="listSkeleton" @selectToAccountingCardNumber="selectToAccountingCardNumber" @selectFromAccountingCardNumber="selectFromAccountingCardNumber" :typeAccount="typeAccount"> 
        <template v-slot:closeIcon>
          <v-card-text class="pa-0 text-end">
            <v-icon class="mt-2 primary--text" @click="$emit('update:dialog', false)">mdi-close</v-icon>
          </v-card-text>
        </template>
      </AccountingCardNumberListTable>
    </v-dialog>
  </div>
</template>

<script>
import AccountingCardNumberListTable from '@/views/companyApp/accountingCard/AccountingCardNumberListTable';

export default {
  name: "AccountingCardDialog",
  props: ["dialog", "typeAccount", "accountingIndex"],
  components: {
    AccountingCardNumberListTable
  },
  data() {
    return {
      listSkeleton: false,
    }
  },
  watch: {
    dialog() {
      this.$store.dispatch('accountingReport/GetAccountCardNumber', {page: 1, limit: 10, order_by: 'name|asc'});
    }
  },
  methods: {
    selectFromAccountingCardNumber(item) {
      this.$emit('fromAccountCardNumberChange', item, this.accountingIndex);
      this.$emit('toAccountCardNumberChange', item, this.accountingIndex);
      this.$emit('update:dialog', false);
    },
    selectToAccountingCardNumber(item) {
      this.$emit('toAccountCardNumberChange', item, this.accountingIndex);
      this.$emit('update:dialog', false);
    }
  },
}
</script>

<style scoped>
::v-deep .v-dialog {
  overflow-x: hidden !important;
  max-height: 80%;
}
</style>