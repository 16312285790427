<template>
  <div>
    <v-card
      class="mx-auto"
      outlined
    >
      <ListSkeleton v-if="listSkeleton"/>
      <v-card-text class="text-h5 pa-0" v-else-if="accountingCardNumberList">
        <slot name="closeIcon"></slot>
        <v-data-table
          :dense="true"
          class="custom-table ma-2 row-pointer"
          :headers="headers"
          :items="accountingCardNumberList"
          :items-per-page="limit"
          :page.sync="page"
          item-key="randomKey"
          style="word-break: break-word"
          :options.sync="pagination"
          item-class="py-4"
          @page-count="pageCount = $event"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }"
          :search="search"
          @dblclick:row="doubleClick"
          @pagination="foundRow"
        >
          <template
            v-slot:top
          >
            <div class="text-body-2 primary--text mb-2">
              <v-row :dense="true">
                <v-col cols="6">
                </v-col>
                <v-col cols="3" class="text-end d-flex">
                  <v-text-field
                    :full-width="false"
                    :dense="true"
                    hide-details="auto"
                    :autofocus="type ? true : false"
                    v-model="search"
                    clearable
                    append-icon="mdi-magnify"
                    :label="$t('common.search')"
                    single-line
                  />
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                    hide-details="auto"
                    height="30%"
                    dense
                    v-model="selectedGroup"
                    :items="groupValue"
                    item-text="name"
                    item-value="id"
                    clearable
                    flat
                    @input="buildUrl"
                    return-object
                    single-line
                    :label="$t('accountingCardReport.balanceSheetSection')"
                  />
                </v-col>
              </v-row>
            </div>
            <v-divider />
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-row class="d-flex">
              <v-col class="mx-auto" cols="12">
                <span class="pl-3 pt-2">{{ $t('common.numberOfTotalRows') }} {{numberOfRows}}</span>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`footer.page-text`]>
            <v-row class="d-flex">
              <v-col class="mx-auto mr-16" cols="4">
                <v-container class="w-100">
                  <v-pagination
                    :total-visible="5"
                    v-model="page"
                    :length="pageCount"
                  ></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text class="text-caption text-center" v-else> {{$t('common.noDataFound')}}</v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ListSkeleton from '@/components/skeleton/ListSkeleton';

export default {
  name: 'AccountingCardNumberListTable',
  props: ['listSkeleton', 'type', 'typeAccount'],
  components: {
    ListSkeleton
  },
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      limit: 10,
      selectedGroup: {},
      pagination: {},
      search: "",
      selectBalanceSheetSection: null,
      numberOfRows: ""
    }
  },
  computed: {
    groupValue() {
      return [
        {id: 1, name: this.$t('accountingCardReport.Employee')},
        {id: 2, name: this.$t('accountingCardReport.Customer')},
        {id: 3, name: this.$t('accountingCardReport.Supplier')},
        {id: 4, name: this.$t('accountingCardReport.Item')},
        {id: 5, name: this.$t('accountingCardReport.Income'), account_type: 0},
        {id: 6, name: this.$t('accountingCardReport.Expenses'), account_type: 1},
        {id: 7, name: this.$t('accountingCardReport.Funds'), account_type: 2},
        {id: 8, name: this.$t('accountingCardReport.Banks'), account_type: 4},
        {id: 9, name: this.$t('accountingCardReport.Others'), account_type: 3},
      ]
    },
    params() {
      return {
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        query: this.search,
        type: this.selectedGroup?.name,
        account_type: this.selectedGroup?.account_type,
      };
    },
    ...mapGetters({
      accountingCardNumberList: 'accountingReport/accountingCardNumberList',
      accessRight: 'accessRight',
      user: 'user'
    }),
    headers() {
      return [
        { text: this.$t('accountingCardReport.accountNumber'), value: "book_keeping_number", width: "130px", align: "start" },
        { text: this.$t('accountingCardReport.accountName'), value: "description", width: "360px" },
        { text: this.$t('accountingCardReport.balanceSheetSection'), value: "balance_sheet_section_description", width: "190px" },
        { text: "randomKey", value: Math.random().toString(36).slice(2), align: ' d-none' }
      ]
    }
  },
  watch: {
    type: {
      handler() {
        this.search = '';
        this.page = 1;
        this.limit = 10;
        this.selectedGroup = null;
      },
      deep: true
    },
    params: {
      handler(newVal, oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
          this.paginate();
          this.page = this.params.page
          this.limit = this.params.itemsPerPage
      },
      deep: true
    },
    '$route.query': {
      handler(newVal,oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
          this.page = Number(this.$route.query.page) || 1
          this.search = this.$route.query.search || ""
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    this.$emit('update:listSkeleton', true);
    this.$store.commit("accountingReport/SET_ACCOUNTINGCARD_NUMBER_QUERY", this.$route.query);
    if(Object.keys(this.$route.query).length){
      this.paginate(false);
    }
  },
  methods: {
    foundRow(pagination) {
      this.numberOfRows = pagination.itemsLength
    },
    async paginate() {
      if (this.user.role.slug === 'admin' || this.user.role.slug === 'manager') {
        const payload = {
          page: this.pagination.page,
          itemsPerPage: this.pagination.itemsPerPage,
          type: this.params.type,
          where: this.params.account_type
        }
        if (payload.where) {
          payload.type, payload.where = 'account_type,'+payload.where
        } else {
          if (payload.where == 0) {
            payload.type, payload.where = 'account_type,'+0
          }
        }
        await this.$store.dispatch('accountingReport/GetAccountCardNumber', payload).then(() => this.$emit('update:listSkeleton', false));
      }
    },
    doubleClick(event, {item}) {
      if (this.typeAccount == 'from') {
        this.$emit('selectFromAccountingCardNumber', item)
        this.$emit('selectToAccountingCardNumber', item)
        this.search = null
        this.selectedGroup = null
      } else if (this.typeAccount == 'to') {
        this.$emit('selectToAccountingCardNumber', item)
        this.search = null
        this.selectedGroup = null
      }
    },
    buildUrl(val) {
      if (this.user.role.slug === 'admin' || this.user.role.slug === 'manager') {
        if (!this.type && val) {
          let obj = {};
          if (typeof val === 'string' || typeof val === 'object') {
            this.page = 1;
            obj.page = 1;
            this.params.page = 1;
          }
          obj.search = this.search;
          obj.page = this.page;
          obj = Object.entries(obj).reduce((acc, [key, val]) => {
            if (key === "id" && val === null) return acc;
            else if (key === "page" && val === 1) return acc;
            else if (!val) return acc;
            return { ...acc, [key]: val };
          }, {});
          this.$store.commit("accountingReport/SET_ACCOUNTINGCARD_NUMBER_QUERY", obj);
        }
      }
    },
  }
}
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
</style>